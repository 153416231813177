$color1: #000000;
$color2: #1E496B;
$color3: #FFFFFF;
$color4: #F27480;
$color5: #DAE1E8;
$color6: #F8FAFD;
$color7: #828282;
$color8: #3E6488;
$color9: #E0E0E0;
$color10: #7E93A8;
$color11: rgba(218, 225, 232, 0.45);
$color12: #F27480;
$color13: #F7F8F9;
$color14: #E1E7EC;
$color15: #E8EAEC;
$color16: #C7C6C7;
$color17: #E1E7EC;
$color18: #939393;
$color19: #D0D0D0;
$color20: #ACACAC;
$color21: #F1F1F1;
$color22: #25213B;
$color23: #3E8AC7;
$color24: #DE5959;
$color25: #F0F4F8;



















