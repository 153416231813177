@import '../../../cssVars.scss';

.form {
	&__input {
    display: flex;
    align-items: center;
		height: 43px;
		margin-top: 9px;
    padding: 0 10px 0 21px;
		font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 600;
    color: #1E496B;
    background: $color3;
    border: 2px solid $color15;
		border-radius: 3px;
		appearance: none;
		outline: none;

		&--width {
			@media(max-width: 1100px) {
				width: 323px !important;
			}
		}

		@media(max-width: 767px) {
			width: 100% !important;
			padding: 0 5px 0 5px;
		}

		&::placeholder {
			font-family: 'Montserrat';
    	font-weight: 600;
    	font-size: 13px;
    	color: $color16;
		}
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill:not(:placeholder-shown) {
	-webkit-text-fill-color: #1E496B !important;
}
