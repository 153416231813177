@import '../../cssVars.scss';

.popup {
  z-index: 30;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  &__message {
    padding: 50px 30px;
    padding-bottom: 30px;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 100%;
    max-width: 400px;
    position: absolute;
    top: 200px;
    left: calc(50vw - 200px);
    font-family: 'Montserrat';
    font-size: 14.1px;
    line-height: 16px;
    color: #1E496B;
    font-weight: 600;

    @media (max-width: 450px) {
      width: 100%;
    	left: 0;
    	padding: 50px 10px;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      overflow-wrap: anywhere;
    }
  }

  &__button {
    max-width: 57vw;
    height: 57px;
    background: $color2;
    box-shadow: 10px 12px 15px $color17;
    box-sizing: border-box;
    border-left: 14px solid $color12;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: $color3;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
		position: relative;
		padding: 0;
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 15px;
    left: 0;
    min-width: 100px;
  }
}
