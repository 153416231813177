.form {

	&__select_container {
		position: relative;
	}

	&__select {
		width: 100%;
		height: 40px;
		border: 1px solid #D8E1E9;
		border-radius: 3px;
		position: relative;
		appearance: none;
		background: #F3F3F3;
		font-family: 'Inter', sans-serif;
		font-size: 16px;
		line-height: 40px;
		color: #000;
		outline: none;
		display: flex;
		justify-content: space-between;
		margin: 20px;
		max-width: 480px;
		cursor: pointer;
	}

	&__triangle {
		background: transparent url('./images/red_triangle.png') no-repeat 50% 50%;
		width: 40px;
		height: 40px;
		transition: transform 0.3s ease;
	}

	&__options {
		position: absolute;
		max-height: 0;
		top: 40px;
		left: 20px;
		width: 480px;
		background: #fff;
		border: 1px solid #D8E1E9;
		border-radius: 3px;
		overflow: hidden;
		transition: max-height 0.4s;
	}

	&__option {
		font-family: 'Inter', sans-serif;
		line-height: 44px;
		font-size: 16px;
		height: 44px;
		margin-bottom: 1px;
	}

	&__option:last-child {
		margin-bottom: 8px;
	}

	&__options_open {
		transition: max-height 0.4s;
		max-height: fit-content;
	}

	&__checkbox>input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}

	&__checkbox>span {
		display: inline-flex;
		width: 100%;
		align-items: center;
		user-select: none;
		font-family: Inter, sans-serif;
		font-size: 16px;
		color:  #25213B;
		cursor: pointer;
	}

	&__checkbox>span::before {
		content: '';
		display: inline-block;
		width: 28px;
		height: 28px;
		background: #fff no-repeat center center;
	}
	&__checkbox>input:not(:disabled):not(:checked)+span:hover::before {
		border-color: #b3d7ff;
	}
	&__checkbox>input:not(:disabled):active+span::before {
		background-color: #b3d7ff;
		border-color: #b3d7ff;
	}
	&__checkbox>input:checked+span::before {
		background-image: url("./images/flag.svg");
		border-radius: 3px;
	}

	&__zone,
	&__text {
		font-family: Inter;
		font-size: 16px;
		line-height: 40px;
		color: #25213B;
		cursor: pointer;
		text-indent: 14px;
	}

	&__zone {
		background-color: #fff;
		border-bottom: 1px solid #B9BDC1;
	}
}

@media screen and (max-width: 925px) {
	.form__select {
		max-width: 300px;
	}
		.form__options {
			max-width: 300px;
	}
}