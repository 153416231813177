.slots {
	max-height: 90%;
	width: 378px;
	overflow-y: auto;
	font-family: Inter;
	margin-top: 30px;

	&__duration {
		font-weight: bold;
		font-size: 18px;
		color: #000000;
	}

	&__date {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #6F6F71;
		margin-bottom: 22px;
	}

	&__name {
		font-weight: 600;
		font-size: 13px;
		color: #F27480;
		margin-bottom: 11px;
	}
}

.slots::-webkit-scrollbar {
	width: 4px;
	background-color: #f3f3f3;
}

.slots::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color:#F27480;
	height: 138px;
}

@media screen and (max-width: 925px) {
	.slots {
		width: 278px;
	}
}