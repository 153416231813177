.container {
	width: 100%;
	max-width: 1100px;
	height: 780px;
	margin: 60px auto;
	border: 1.5px solid #E6E7E8;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&__logo {
		display: block;
		position: absolute;
		right: -76px;
		top: -76px;
	}

	&__heading {
		display: block;
		font-family: Inter;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #6F6F71;
		margin: 43px 20px 16px;
	}
}


	@media screen and (max-width: 925px) {
		.container {
			height: fit-content;
			justify-content: center;
			&__logo {
				display: none;
			}
		}
	}
