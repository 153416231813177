.meeting {
	width: 278px;
	height: 49px;

	margin: 11px 0;
	line-height: 49px;
	letter-spacing: 0.12px;
	display: flex;
	justify-content: space-between;


&__date {
	font-size: 14px;
	font-family: Inter;
	font-weight: 500;
	text-align: center;
	color: #396888 ;
	background-color:#F3F3F3;
	min-width: 134px;
	flex-grow: 1;
	border-radius: 5px;
	border: 1px solid transparent;
	transition: all 0.1s;
	user-select: none;
	cursor: pointer;
	z-index: 1;
	}

	&__date_selected {
		background-color:#F3DADC;
		color: #F27480;
		font-weight: bold;
	}

	&__date:hover {
		border: 1px solid #F27480;
		color: #F27480
	}

	&__button {
		width: 134px;
		height: 49px;
		border: none;
		outline: none;
		user-select: none;
		margin-left: 10px;

		background: #F27480;
		border-radius: 6px;
		cursor: pointer;

		font-family: Inter;
		font-weight: bold;
		font-size: 16px;
		color: #fff;
		z-index: 1;
	}
}